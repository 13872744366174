<template>
    <div class='SummaryDetails-container'>
        <CSDialog
                dialog-title="查看公摊汇总详情"
                dialog-width='680px'
                class="tenant-detail-dialog"
                :dialog-visible="dealResultVisible"
                @onClose="dealResultVisible = false"
                dialog-header-class="alert-bg"
                :dialog-show-confirm-btn="false"
                dialog-cancel-btn-text="关闭"
        >
            <template v-slot:dialog-content>
                <div class="wrapper">
                    <div class="from-item">
                        <div class="from-item-top">计费起始日抄表时间</div>
                        <div class="from-item-bottom">
                            <div class="item-bottom">
                                <div class="item-from">抄表时间</div>
                                <div>{{items.collectBillStartTonDate}}</div>
                            </div>
                            <div class="item-bottom">
                                <div class="item-from">抄表读数</div>
                                <div>{{items.collectBillStartDateTon}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="from-item">
                        <div class="from-item-top">计费截止日抄表记录</div>
                        <div class="from-item-bottom">
                            <div class="item-bottom">
                                <div class="item-from">抄表时间</div>
                                <div>{{items.collectBillEndTonDate}}</div>
                            </div>
                            <div class="item-bottom">
                                <div class="item-from">抄表读数</div>
                                <div>{{items.collectBillEndDateTon}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="from-item">
                        <div class="from-item-top">{{isElectric?'园区总用电量':'园区总用水量'}}</div>
                        <div class="from-item-bottom">
                            <div class="item-bottom">
                                <div class="item-from">{{items.regionSum}}{{isElectric?'度':'吨'}} </div>
                            </div>
                        </div>
                    </div>
                    <div class="from-item">
                        <div class="from-item-top">{{isElectric?'私区总用电量':'私区总用水量'}}</div>
                        <div class="from-item-bottom">
                            <div class="item-bottom">
                                <div class="item-from">{{items.privateSum}}{{isElectric?'度':'吨'}}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </template>
        </CSDialog>
    </div>
</template>

<script>
    import CSDialog from "@/components/common/CSDialog";
    export default {
        name: "SummaryDetails",
        components: {
            CSDialog
        },
        props:{
            isElectric:{
                default: false
            }
        },
        created() {
            this.$vc.on("SummaryDetails",(item) => {
                this.dealResultVisible = true;
                this.items = item;
            })
        },
        data() {
            return {
                items:null,
                dealResultVisible: false
            }
        }
    }
</script>

<style scoped>
    .wrapper{
        padding: 30px;
    }
    .from-item {
        font-size: 24px;
        color: #000000;
        display: flex;
        align-items: top;
        text-align: right;
        margin-bottom: 22px;
    }

    .from-item-top{
        width: 220px;
        margin-right: 40px;

    }
    .item-bottom{
        display: flex;
    }
    .item-from{
        margin-right: 20px;
    }
</style>
<template>
  <div class='SummarViewDetails-container'>
    <CSDialog
        :dialog-title="dialogTitle"
        :dialog-width='dialogWidth'
        class="tenant-detail-dialog"
        :dialog-visible="dealResultVisible"
        @onClose="dealResultVisible = false"
        dialog-header-class="alert-bg"
        :dialog-show-confirm-btn="false"
        dialog-cancel-btn-text="关闭"
        :key="new Date().getTime()"
    >
      <template v-slot:dialog-content>
        <div class="wrapper">
          <div class="division" v-if="dialogTitle=='查看修改账单明细'">

          </div>
          <div class="wrapper-item" v-if="dialogTitle=='查看修改账单明细'">
            <div class="from-tiem from-top">
            </div>
            <div class="wrappper-title from-tiem">
              修改前
            </div>
            <div class="wrappper-title from-tiem">
              修改后
            </div>
          </div>
          <div class="wrapper-item">
            <div class="from-tiem from-top">
              计费起止日期
            </div>
            <div class="from-tiem">
              {{ items.billStartDate }}至{{ items.billEndDate }}
            </div>
            <div class="from-tiem" v-if="dialogTitle=='查看修改账单明细'">
              {{ item.billStartDate }}至{{ item.billEndDate }}
            </div>
          </div>
          <div class="wrapper-item">
            <div class="from-tiem from-top">
              {{ isElectric ? '公摊总用电量' : '公摊总用水量' }}
            </div>
            <div class="from-tiem">
              {{ items.collectDosage }}{{ isElectric ? '度' : '吨' }}
            </div>
            <div class="from-tiem" v-if="dialogTitle=='查看修改账单明细'">
              {{ item.collectDosage }}{{ isElectric ? '度' : '吨' }}
            </div>
          </div>

          <div class="wrapper-item">
            <div class="from-tiem from-top">
              {{ isElectric ? '公摊电价' : '公摊水价' }}
            </div>
            <div class="from-tiem">
              {{ items.collectPrice }}{{ isElectric ? '元/度' : '元/吨' }}
            </div>
            <div class="from-tiem" v-if="dialogTitle=='查看修改账单明细'">
              {{ item.collectPrice}}{{ isElectric ? '元/度' : '元/吨' }}
            </div>
          </div>
          <div class="wrapper-item">
            <div class="from-tiem from-top">
              {{ isElectric ? '公摊电费总额' : '公摊水费总额' }}
            </div>
            <div class="from-tiem">
              {{ items.collectAmount }}元
            </div>
            <div class="from-tiem" v-if="dialogTitle=='查看修改账单明细'">
              {{ item.collectAmount }}元
            </div>
          </div>
          <div class="wrapper-item">
            <div class="from-tiem from-top">
              单位面积公摊费
            </div>
            <div class="from-tiem">
              {{ items.collectPriceAvg }}元
            </div>
            <div class="from-tiem" v-if="dialogTitle=='查看修改账单明细'">
              {{ item.collectPriceAvg }}{{ isElectric ? '度' : '吨' }}
            </div>
          </div>
          <div class="wrapper-item">
            <div class="from-tiem from-top">
              房间号
            </div>
            <div class="from-tiem">
              {{  item.buildingName+item.floor+'层'+item.roomNo  }}
            </div>
            <div class="from-tiem" v-if="dialogTitle==='查看修改账单明细'">
              {{  item.buildingName+item.floor+'层'+item.roomNo  }}
            </div>
          </div>
          <div class="wrapper-item">
            <div class="from-tiem from-top">
              水电公摊承担面积
            </div>
            <div class="from-tiem">
              {{ items.collectSpace }}
            </div>
            <div class="from-tiem" v-if="dialogTitle=='查看修改账单明细'">
              {{ item.collectSpace }}
            </div>
          </div>
          <div class="wrapper-item">
            <div class="from-tiem from-top">
              账单金额
            </div>
            <div class="from-tiem">
              {{ items.billAmount }}元
            </div>
            <div class="from-tiem" v-if="dialogTitle=='查看修改账单明细'">
              {{ item.billAmount }}元
            </div>
          </div>
          <div class="wrapper-item">
            <div class="from-tiem from-top">
              企业/个人
            </div>
            <div class="from-tiem">
              {{ items.lesseeName }}
            </div>
            <div class="from-tiem" v-if="dialogTitle=='查看修改账单明细'">
              {{ item.lesseeName }}
            </div>
          </div>
        </div>

      </template>
    </CSDialog>
  </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog";

export default {
  name: "SummarViewDetails",
  created() {

    this.$vc.on('summarViewDetails', (item) => {
      setTimeout(() => {
        if(this.dialogTitle=='查看修改账单明细'){
          console.log('查看明细');
          this.item = item.items;
          let detailedArr = item.detailedList.filter(res => {
            return   res.billId == this.item.billId
          });
          let indexOf = null;
          for(let i = 0;i < detailedArr.length;i++){
            if(detailedArr[i].createTime == this.item.createTime){
              indexOf = i;
              break;
            }
          }
          console.log('detailedArr');
          console.log(detailedArr);
          console.log(indexOf);
          if(detailedArr.length-1 <= indexOf){
            this.items ={};
          }else{
            this.items =detailedArr[indexOf + 1];
          }
          console.log('this.items');
          console.log(this.items);
        }
        else{
          console.log('查看不');
          this.items = item.items;
        }
        this.dealResultVisible = true;
      },200)

    });
  },
  props: {
    isElectric: {
      default: false
    },
    dialogTitle: {
      default: '查看修改账单明细'
    },
    dialogWidth: {
      default: '1000px'
    }
  },
  data() {
    return {
      item: "",
      items: "",
      dealResultVisible: false,

    }
  },
  components: {
    CSDialog
  }
}
</script>

<style scoped>
.wrapper {
  padding: 30px;
  font-size: 24px;
  color: #000000;
  position: relative;
}

.wrapper-item {
  display: flex;
  margin-bottom: 18px;
}

.from-tiem {
  margin-right: 60px;
  min-width: 315px;
}

.wrapper-item .from-tiem:last-child {
  margin-right: 0;
}

.from-top {
  min-width: 195px;
  text-align: right;
}

.wrappper-title {
  position: relative;
  padding-left: 16px;
}

.wrappper-title::after {
  width: 10px;
  height: 24px;
  background: rgba(0, 182, 148, 1);
  position: absolute;
  content: '';
  left: 0px;
  top: calc(50% - 12px);
}

.division {
  width: 1px;
  height: 457px;
  background: rgba(221, 221, 221, 1);
  opacity: 1;
  position: absolute;
  left: 252px;
  top: 92px
}
</style>
